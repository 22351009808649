import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import Glide from '@glidejs/glide';
import api from '../../providers/api';
import { Button } from 'react-bootstrap';
import './quality_products.css';

import Provider from '../../providers/services';
class QualityProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qualitys: []
    };
  }
  componentDidMount() {
    Provider.getQuality().then(res => {
      this.setState({ qualitys: res.data.galleryQuality });
      new Glide('.glide_quality_products', {
        type: 'carousel'
      }).mount();
    });
  }
  downloadPdf(data) {
    var anchor = document.createElement('a');
    anchor.href = data;
    anchor.target = '_blank';
    anchor.download = 'ficha tecnica';
    anchor.click();
  }
  sliderContent(t, data) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <div className='row' style={{ justifyContent: 'center' }}>
          <div
            className='col-12 col-lg-5'
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <img
              className='slider_image'
              src={api.images + 'gallery/quality/' + data.img}
              alt='aire split'
            />
          </div>

          <div
            className='col-12 col-lg-5'
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              color: 'white',
              textAling: 'center'
            }}
          >
            <h2>{data.title}</h2>
            <p>{data.description}</p>
          </div>
        </div>
        <Button
          onClick={() =>
            this.downloadPdf(
              api.images + 'gallery/quality/' + data.data_sheet_pdf
            )
          }
          variant='primary'
          type='submit'
        >
          Ver certificado
        </Button>
      </div>
    );
  }
  render() {
    let { t } = this.props;
    return (
      <>
        <div ref={this.props.quality} className='quality_products'>
          <h1>
            <b>{t('the_quality_of')}</b> {t('our_products')}
          </h1>
          <div className='container'>
            <div className='glide glide_quality_products'>
              <div className='glide__track' data-glide-el='track'>
                <ul className='glide__slides'>
                  {this.state.qualitys.map(data => (
                    <li className='glide__slide' key={data.id}>
                      {this.sliderContent(t, data)}
                    </li>
                  ))}
                </ul>
              </div>

              <div className='glide__arrows' data-glide-el='controls'>
                <button
                  className='glide__arrow glide__arrow--left'
                  data-glide-dir='<'
                >
                  <i className='fas fa-chevron-left'></i>
                </button>
                <button
                  className='glide__arrow glide__arrow--right'
                  data-glide-dir='>'
                >
                  <i className='fas fa-chevron-right'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  quality: state.quality
});
const mapDispatchToProps = dispatch => ({});
export default compose(
  translate('title'),
  connect(mapStateToProps, mapDispatchToProps)
)(QualityProducts);
