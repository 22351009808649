import {
  createStore
} from 'redux'
import {
  createRef
} from 'react'

const initialState = {
  lang: 'es',
  home: [],
  galleryCustomer: [],
  search: '',
  badge: 0,
  email_client: '',
  productsref: new createRef(),
  aboutus: new createRef(),
  contact: new createRef(),
  quality: new createRef()
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HOME': {
      return ({
        ...state,
        home: action.data.galleryHome,
        galleryCustomer: action.data.galleryCustomer,
        email_client: action.data.company[0].sales_mail
      })
    }
    case 'SET_BADGE': {
      return ({
        ...state,
        badge: action.badge
      })
    }
    case 'MOVE_REF': {
      action.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
      return ({
        ...state
      })
    }
    case 'SEARCH_TEXT': {
      return ({
        ...state,
        search: action.search
      })
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default createStore(reducer)