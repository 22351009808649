import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import './products.css';
import { Form, Card, Button } from 'react-bootstrap';

import api from '../../providers/api';
import Provider from '../../providers/services';
import Glide from '@glidejs/glide';
class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      category: [],
      sub_category: [],
      category_select: '',
      subcategory_select: '',
      show: false,
      product: null,
      glide: null,
      width: 768
    };
    this.getProducts();
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions());
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  getProducts() {
    Provider.getProducts().then(res => {
      console.log(res);
      this.setState({
        products: res.data.products,
        category: res.data.category
      });
      this.setGlide();
    });
  }
  setGlide() {
    setTimeout(() => {
      let glide = new Glide('.glide_products', {
        type: 'slider',
        perView: this.state.width > 768 ? 3 : 2,
        startAt: 0,
        focusAt: 0
      });
      glide.mount();
    }, 100);
  }
  changeCategory(e) {
    this.setGlide();
    this.getValidSubCategory(e.target.value);
    this.setState({
      category_select: e.target.value
    });
  }
  changeSubCategory(e) {
    this.setGlide();
    this.setState({
      subcategory_select: e.target.value
    });
  }
  changeSearch(e) {
    this.setState({ search: e.target.value });
  }
  getValidSubCategory(category_select) {
    if (category_select !== '') {
      let subs = this.state.products
        .filter(a => a.nameCategory === category_select)
        .filter((a, b) => a.nameSubCategory !== b.nameSubCategory)
        .map(a => {
          return a.nameSubCategory;
        });
      console.log(subs);
      this.setState({
        sub_category:
          subs.length > 1 ? subs.filter((v, i) => subs.indexOf(v) === i) : subs
      });
    } else {
      this.setState({
        subcategory_select: '',
        sub_category: []
      });
    }
  }
  handleClose() {
    this.setState({ product: null, show: false });
  }
  handleOpen(id) {
    Provider.getProduct(id).then(res => {
      console.log(res.data.product[0]);
      this.setState({ product: res.data.product[0], show: true });
    });
  }
  downloadPdf(data) {
    var anchor = document.createElement('a');
    anchor.href = data;
    anchor.target = '_blank';
    anchor.download = 'ficha tecnica';
    anchor.click();
  }
  addToCart(product) {
    let cart = JSON.parse(localStorage.getItem('cart'));
    if (cart) {
      if (cart.filter(a => a.id === product.id) <= 0) {
        cart.push(product);
        localStorage.setItem('cart', JSON.stringify(cart));
      } else {
        cart.map(a => a.count++);
        localStorage.setItem('cart', JSON.stringify(cart));
      }
    } else {
      cart = [product];
      localStorage.setItem('cart', JSON.stringify(cart));
    }
    this.props.setBadge(cart.length);
    window.location.assign('/daer/home/cart');
  }
  render() {
    let { t } = this.props;
    return (
      <>
        <div ref={this.props.productsref} className='products'>
          <h1>
            <b>{t('our')}</b> {t('products')}
          </h1>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-lg-3'>
                <Form.Group controlId='exampleForm.ControlSelect2'>
                  <Form.Control
                    size='lg'
                    as='select'
                    value={this.state.category_select}
                    onChange={this.changeCategory.bind(this)}
                  >
                    <option value=''>Todas las Categorías</option>
                    {this.state.category.map(data => (
                      <option key={data.id} value={data.name}>
                        {data.name}
                      </option>
                    ))}
                  </Form.Control>

                  <i className='fa fa-chevron-down'></i>
                </Form.Group>
              </div>
              <div className='col-12 col-lg-3'>
                <Form.Group controlId='exampleForm.ControlSelect2'>
                  <Form.Control
                    size='lg'
                    as='select'
                    value={this.state.subcategory_select}
                    onChange={this.changeSubCategory.bind(this)}
                  >
                    <option value=''>Todas las SubCategorías</option>
                    {this.state.sub_category.map((data, index) => (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    ))}
                  </Form.Control>
                  <i className='fa fa-chevron-down'></i>
                </Form.Group>
              </div>
              <div className='col-12 col-lg-6'>
                <h3 className='search_number'>
                  {this.state.products
                    .filter(a =>
                      this.state.category_select === ''
                        ? a
                        : a.nameCategory === this.state.category_select
                    )
                    .filter(a =>
                      this.state.subcategory_select === ''
                        ? a
                        : a.nameSubCategory === this.state.subcategory_select
                    )
                    .filter(
                      a =>
                        a.name
                          .toLowerCase()
                          .indexOf(this.props.search.toLowerCase()) >= 0
                    ).length > 0
                    ? this.state.products
                        .filter(a =>
                          this.state.category_select === ''
                            ? a
                            : a.nameCategory === this.state.category_select
                        )
                        .filter(a =>
                          this.state.subcategory_select === ''
                            ? a
                            : a.nameSubCategory ===
                              this.state.subcategory_select
                        ).length +
                      (this.state.products.length > 1
                        ? ' productos encontrados'
                        : ' producto encontrado')
                    : 'No hay productos disponibles'}{' '}
                </h3>
              </div>
            </div>

            <div>
              <div className='glide glide_products'>
                <div className='glide__track' data-glide-el='track'>
                  <ul className='glide__slides'>
                    {this.state.products
                      .filter(a =>
                        this.state.category_select === ''
                          ? a
                          : a.nameCategory === this.state.category_select
                      )
                      .filter(a =>
                        this.state.subcategory_select === ''
                          ? a
                          : a.nameSubCategory === this.state.subcategory_select
                      )
                      .filter(
                        a =>
                          a.name
                            .toLowerCase()
                            .indexOf(this.props.search.toLowerCase()) >= 0
                      )
                      .map(data => (
                        <li className='glide__slide' key={data.id}>
                          {
                            <Card
                              onClick={() => this.handleOpen(data.id)}
                              style={{ cursor: 'pointer' }}
                            >
                              <Card.Img
                                variant='top'
                                src={
                                  api.images + 'products/' + data.img_principal
                                }
                              />
                              <Card.Body>
                                <Card.Title> {data.name}</Card.Title>
                                <Card.Text>{data.description}</Card.Text>
                              </Card.Body>
                            </Card>
                          }
                        </li>
                      ))}
                  </ul>
                </div>

                <div className='glide__arrows' data-glide-el='controls'>
                  <button
                    className='glide__arrow glide__arrow--left'
                    data-glide-dir='<'
                  >
                    <i className='fas fa-chevron-left'></i>
                  </button>
                  <button
                    className='glide__arrow glide__arrow--right'
                    data-glide-dir='>'
                  >
                    <i className='fas fa-chevron-right'></i>
                  </button>
                </div>
              </div>
            </div>

            {this.state.product ? (
              <div>
                <Card className='product_card resume'>
                  <div className='container'>
                    <div className='row'>
                      <div
                        className='col-12 col-sm-6'
                        style={{
                          justifyContent: 'center',
                          display: 'flex'
                        }}
                      >
                        <img
                          style={{
                            width: '45%',
                            justifyCcontent: 'center',
                            display: 'flex'
                          }}
                          src={
                            api.images +
                            'products/' +
                            this.state.product.img_principal
                          }
                          alt='aire split'
                        />
                      </div>
                      <div
                        className='col-12 col-sm-6'
                        style={{
                          justifyContent: 'center',
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <h2> {this.state.product.name}</h2>
                        <Card.Text>{this.state.product.description}</Card.Text>
                        <div
                          className='row'
                          style={{ justifyContent: 'space-evenly' }}
                        >
                          <Button
                            variant='secundary'
                            type='submit'
                            onClick={() =>
                              this.downloadPdf(
                                api.images +
                                  'products/' +
                                  this.state.product.data_sheet_pdf
                              )
                            }
                          >
                            Descargar ficha tecnica
                          </Button>
                          <Button
                            variant='primary'
                            type='submit'
                            onClick={() => this.addToCart(this.state.product)}
                          >
                            Solicitar
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>{' '}
                </Card>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  productsref: state.productsref,
  search: state.search
});
const mapDispatchToProps = dispatch => ({
  setBadge(n) {
    dispatch({
      type: 'SET_BADGE',
      badge: n
    });
  }
});
export default compose(
  translate('title'),
  connect(mapStateToProps, mapDispatchToProps)
)(Products);
