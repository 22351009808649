import React, { Component } from 'react';
import { TranslatorProvider } from 'react-translate';
import { connect } from 'react-redux';
import Header from './header/header';
import Footer from './footer/footer';
import Contact from './contact/contact';
import Clients from './clients/clients';
import QualityProducts from './quality_products/quality_products';
import Products from './products/products';
import AboutUs from './about_us/about_us';
import Intro from './intro/intro';
import Cart from './cart/cart';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Provider from '../providers/services';

class Components extends Component {
  componentDidMount() {
    this.getHomeContent();
    let cart = JSON.parse(localStorage.getItem('cart'));
    if (cart) {
      this.props.setBadge(cart.length);
    }
  }
  getHomeContent() {
    Provider.getHomeContent().then(res => {
      console.log(res.data);
      this.props.setDataHome(res.data);
    });
  }
  render() {
    return (
      <>
        <TranslatorProvider
          translations={require('../assets/i18n/' + this.props.lang + '.json')}
        >
          <Router basename='/daer/home'>
            <Header />
            <Switch>
              <Route exact path='/'>
                <Intro />
                <AboutUs />
                <Products />
                <QualityProducts />
                <Clients />
                <Contact />
              </Route>
              <Route path='/cart'>
                <Cart />
              </Route>
            </Switch>
            <Footer />
          </Router>
        </TranslatorProvider>
      </>
    );
  }
}
const mapStateToProps = state => ({
  lang: state.lang
});
const mapDispatchToProps = dispatch => ({
  setDataHome(data) {
    dispatch({
      type: 'HOME',
      data: data
    });
  },
  setBadge(n) {
    dispatch({
      type: 'SET_BADGE',
      badge: n
    });
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Components);
