import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';

import Glide from '@glidejs/glide';
import Split from '../../assets/images/aire_split.png';

import api from '../../providers/api';
import Provider from '../../providers/services';
import './about_us.css';

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trajectory: [],
      width: 768
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions());
    this.getAbout();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  getAbout() {
    Provider.getAbout().then(res => {
      this.setState({ trajectory: res.data.trajectory });
      new Glide('.glide_about_us', {
        type: 'carousel',
        focusAt: 'center',
        perView: this.state.width > 991 ? 3 : 1,
        hoverpause: true
      }).mount();
    });
  }

  render() {
    return (
      <>
        <div ref={this.props.aboutus} className='about_us'>
          <h1>
            <b>Quienes</b> somos
          </h1>
          <div className='container'>
            <div className='glide glide_about_us' style={{ zIndex: '3000' }}>
              <div className='glide__track' data-glide-el='track'>
                <ul className='glide__slides'>
                  {this.state.trajectory.map(data => (
                    <li className='glide__slide'>
                      <div className='about_img'>
                        <p>DESDE {data.date.split('-')[0]}</p>
                        <img
                          src={api.images + 'trajectory/' + data.img}
                          alt='empresa'
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className='glide__arrows' data-glide-el='controls'>
                <button
                  className='glide__arrow glide__arrow--left'
                  data-glide-dir='<'
                >
                  <i className='fas fa-chevron-left'></i>
                </button>
                <button
                  className='glide__arrow glide__arrow--right'
                  data-glide-dir='>'
                >
                  <i className='fas fa-chevron-right'></i>
                </button>
              </div>
            </div>
          </div>

          <img className='split' src={Split} alt='aire split' />
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  aboutus: state.aboutus
});
const mapDispatchToProps = dispatch => ({});
export default compose(
  translate('title'),
  translate('about_us'),
  connect(mapStateToProps, mapDispatchToProps)
)(AboutUs);
