import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import logo from '../../assets/images/logo.png';
import './footer.css';
import { Link } from 'react-router-dom';
class Footer extends Component {
  render() {
    let { t } = this.props;
    return (
      <>
        <div className='footer-content'>
          <div className='footer__brand'>
            <img src={logo} style={{ width: '120px' }} alt='logo daer' />
            <div className='nav-icons'>
              <a href='http://facebook.com'>
                <i className='fab fa-facebook-f nav-icon'></i>
              </a>
              <a href='http://instagram.com'>
                <i className='fab fa-instagram nav-icon'></i>
              </a>
            </div>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-lg-4'>
                <div className='info'>
                  <i className='fas fa-phone-alt icon'></i>
                  <div>
                    <p>(011) 4451- 7536</p>
                  </div>
                </div>
                <div className='info'>
                  <i className='fas fa-at icon'></i>
                  <div>
                    <p>CDANIEL2R@GMAIL.COM</p>
                    <p>ANNYRR@HOTMAIL.COM</p>
                  </div>
                </div>
                <div className='info'>
                  <i className='fas fa-map-marked-alt icon'></i>
                  <div>
                    <p>CESAR MALNATTI 1750 </p>
                    <p>SAN MIGUEL</p>
                    <p>BUENOS AIRES ARGENTINA</p>
                    <p>CARLOS RODRIGUEZ</p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-4 footer_menu'>
                <Link to='/'>
                  <h4>{t('home')}</h4>
                </Link>
                <h4 onClick={() => this.props.moveToRef(this.props.aboutus)}>
                  {t('about')} {t('us')}
                </h4>
                <h4
                  onClick={() => this.props.moveToRef(this.props.productsref)}
                >
                  {t('products')}
                </h4>
              </div>
              <div className='col-12 col-lg-4 footer_menu'>
                <h4 onClick={() => this.props.moveToRef(this.props.quality)}>
                  {t('quality')}
                </h4>
                <h4>{t('news')}</h4>
                <h4 onClick={() => this.props.moveToRef(this.props.contact)}>
                  {t('online_inquiries')}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  productsref: state.productsref,
  aboutus: state.aboutus,
  contact: state.contact,
  quality: state.quality
});
const mapDispatchToProps = dispatch => ({
  moveToRef(ref) {
    dispatch({
      type: 'MOVE_REF',
      ref: ref
    });
  }
});
export default compose(
  translate('title'),
  connect(mapStateToProps, mapDispatchToProps)
)(Footer);
