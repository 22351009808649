import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import api from '../../providers/api';
import Glide from '@glidejs/glide';
import './clients.css';
class Clients extends Component {
  componentDidUpdate() {
    new Glide('.glide_client', {
      type: 'carousel',
      perView: 5,
      focusAt: 'center',
      animationDuration: 3000,
      autoplay: 100
    }).mount();
  }
  render() {
    let { t } = this.props;
    return (
      <>
        <div className='clients'>
          <h1>
            <b>{t('our')}</b> {t('clients')}
          </h1>
          <div className='container'>
            <div className='glide glide_client'>
              <div className='glide__track' data-glide-el='track'>
                <ul className='glide__slides'>
                  {this.props.galleryCustomer.map(data => (
                    <li className='glide__slide' key={data.id}>
                      <div className='client_img'>
                        <img
                          src={api.images + 'gallery/customer/' + data.img}
                          alt={data.title}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  galleryCustomer: state.galleryCustomer
});
const mapDispatchToProps = dispatch => ({});
export default compose(
  translate('title'),
  connect(mapStateToProps, mapDispatchToProps)
)(Clients);
