import api from './api'
import axios from 'axios';


const getHomeContent = async () => {
  return await axios.get(api.url + 'home')
}

const getQuality = async () => {
  return await axios.get(api.url + 'quality')
}

const getProducts = async () => {
  return await axios.get(api.url + 'products?typeFilter=1')
}

const getProduct = async (id) => {
  return await axios.get(api.url + 'products/' + id)
}

const getAbout = async () => {
  return await axios.get(api.url + 'about')
}
const sendMail = async (data) => {
  var formdata = new FormData();
  formdata.append('email', data.email)
  return await axios.post(api.email + 'contactemail.php', data)
}
const sendMailSolicitud = async (data) => {
  return await axios.post(api.email + 'solicitudemail.php', data)
}
export default {
  getHomeContent,
  getQuality,
  getProducts,
  getProduct,
  getAbout,
  sendMail,
  sendMailSolicitud
}