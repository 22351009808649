import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { Form, Button } from 'react-bootstrap';
import Provider from '../../providers/services';
import './contact.css';
import toastr from 'toastr';
class Contect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      cuit: '',
      mensaje: '',
      telefono: '',
      razon: '',
      email_client: ''
    };
    this.sendMail = this.sendMail.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  handleInput(e) {
    let name = e.target.name;
    this.setState({ [name]: e.target.value });

    this.setState({ email_client: this.props.email_client });
  }
  validateData() {
    toastr.options = {
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut'
    };
    if (
      this.state.email !== '' &&
      this.state.name !== '' &&
      this.state.telefono !== ''
    ) {
      let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      console.log(regex);

      if (!regex.test(this.state.email)) {
        toastr.error('Debe ingresar un correo valido');
        return false;
      } else {
        return true;
      }
    } else {
      toastr.error('Debe rellenar todos los campos');
      return false;
    }
  }
  sendMail() {
    if (this.validateData()) {
      Provider.sendMail(this.state).then(res => {
        console.log(res.data);
        if (res.data === true) {
          toastr.success('Mensaje enviado exitosamente');
          this.setState({
            name: '',
            email: '',
            cuit: '',
            mensaje: '',
            telefono: '',
            razon: ''
          });
        } else {
          toastr.error('Ha ocurrido un error, no se pudo enviar el mensaje');
        }
      });
    }
  }
  render() {
    let { t } = this.props;
    return (
      <>
        <div ref={this.props.contact} className='contact_form'>
          <h1>{t('contact')}</h1>
          <div className='container '>
            <Form>
              <Form.Group controlId='formname'>
                <Form.Control
                  size='lg'
                  type='text'
                  value={this.state.name}
                  name='name'
                  placeholder={'Nombre y Apellido'}
                  onChange={this.handleInput}
                />
              </Form.Group>
              <Form.Group controlId='formrazonsocial'>
                <Form.Control
                  size='lg'
                  type='text'
                  name='razon'
                  value={this.state.razon}
                  placeholder={'Razón Social'}
                  onChange={this.handleInput}
                />
              </Form.Group>
              <Form.Group controlId='formcuit'>
                <Form.Control
                  value={this.state.cuit}
                  size='lg'
                  type='text'
                  name='cuit'
                  placeholder={'Cuit'}
                  onChange={this.handleInput}
                />
              </Form.Group>
              <Form.Group controlId='formphone'>
                <Form.Control
                  value={this.state.telefono}
                  size='lg'
                  type='text'
                  name='telefono'
                  placeholder={'Teléfono'}
                  onChange={this.handleInput}
                />
              </Form.Group>
              <Form.Group controlId='formemail'>
                <Form.Control
                  size='lg'
                  type='text'
                  value={this.state.email}
                  name='email'
                  placeholder={'Dirección de Correo'}
                  onChange={this.handleInput}
                  required
                />
              </Form.Group>
              <Form.Group controlId='formmensaje'>
                <Form.Control
                  size='lg'
                  as='textarea'
                  rows='4'
                  value={this.state.mensaje}
                  name='mensaje'
                  onChange={this.handleInput}
                  placeholder={'Mensaje'}
                />
              </Form.Group>
              <Button variant='secundary' onClick={() => this.sendMail()}>
                Enviar
              </Button>
            </Form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  contact: state.contact,
  email_client: state.email_client
});
const mapDispatchToProps = dispatch => ({});
export default compose(
  translate('buttons'),
  translate('title'),
  connect(mapStateToProps, mapDispatchToProps)
)(Contect);
