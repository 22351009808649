import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navbar } from 'react-bootstrap';
import logo from '../../assets/images/logo.jpeg';
import './header.css';
import { Link } from 'react-router-dom';
class Header extends Component {
  constructor(props) {
    super(props);
    this.setSearchText = this.setSearchText.bind(this);
    this.redirect = this.redirect.bind(this);
  }
  setSearchText(e) {
    this.props.changeSearch(e.target.value);
  }
  redirect() {
    if (window.location.pathname !== '/cart') {
      this.props.moveToRef(this.props.productsref);
    } else {
      window.location.replace('/daer/home');
    }
  }
  render() {
    return (
      <div
      /* style={{
          position: 'fixed',
          width: '-webkit-fill-available',
          backgroundColor: 'white',
          zIndex: '200'
        }} */
      >
        <Navbar>
          <Navbar.Brand href='/daer/home'>
            <img src={logo} style={{ width: '160px' }} alt='logo daer' />
          </Navbar.Brand>
          <div className='nav-icons'>
            <i
              onClick={() => this.redirect()}
              className='fas fa-search nav-icon'
            ></i>

            <hr className='separator'></hr>
            <Link to='/cart'>
              <i className='fas fa-shopping-cart nav-icon'>
                {this.props.badge > 0 ? (
                  <span className='badge'>{this.props.badge}</span>
                ) : null}
              </i>
            </Link>
          </div>
        </Navbar>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  badge: state.badge,
  search: state.search,
  productsref: state.productsref
});
const mapDispatchToProps = dispatch => ({
  changeSearch(s) {
    dispatch({
      type: 'SEARCH_TEXT',
      search: s
    });
  },
  moveToRef(ref) {
    dispatch({
      type: 'MOVE_REF',
      ref: ref
    });
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
