import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Provider from '../../providers/services';
import './cart.css';
import { Form, Button } from 'react-bootstrap';
import toastr from 'toastr';
class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      name: '',
      email: '',
      cuit: '',
      mensaje: '',
      telefono: '',
      razon: '',
      email_client: this.props.email_client
    };
    this.handleInput = this.handleInput.bind(this);
  }
  componentDidMount() {
    let cart = JSON.parse(localStorage.getItem('cart'));
    if (cart) {
      this.setState({ products: cart });
    }

    this.setState({ email_client: this.props.email_client });
  }
  handleInput(e) {
    let name = e.target.name;
    this.setState({ [name]: e.target.value });
  }
  getTotal() {
    var total = 0;
    this.state.products.forEach(data => {
      total += data.count * data.sale_price;
    });
    console.log(total);
    return total;
  }
  deleteItem(i) {
    let products = this.state.products;
    products.splice(i, 1);
    localStorage.setItem('cart', JSON.stringify(products));
    this.setState({ products: products });
    this.props.setBadge(products.length);
  }
  plus(i) {
    let productscart = this.state.products;
    productscart[i].count++;
    this.setState({ products: productscart });
    localStorage.setItem('cart', JSON.stringify(productscart));
  }
  minus(i) {
    let productscart = this.state.products;
    productscart[i].count--;
    this.setState({ products: productscart });
    localStorage.setItem('cart', JSON.stringify(productscart));
  }
  sendMail() {
    if (this.validateData())
      Provider.sendMailSolicitud(this.state).then(res => {
        console.log(res.data);
        if (res.data === true) {
          localStorage.clear();
          toastr.success('Solicitud enviada exitosamente');
          this.setState({
            products: [],
            name: '',
            email: '',
            cuit: '',
            mensaje: '',
            telefono: '',
            razon: ''
          });
        } else {
          toastr.error('Ha ocurrido un error, no se pudo enviar el mensaje');
        }
      });
  }
  validateData() {
    toastr.options = {
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut'
    };
    if (this.state.products.length > 0) {
      if (
        this.state.email !== '' &&
        this.state.name !== '' &&
        this.state.telefono !== ''
      ) {
        let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(this.state.email)) {
          toastr.error('Debe ingresar un correo valido');
          return false;
        } else {
          return true;
        }
      } else {
        toastr.error('Debe rellenar todos los campos');
        return false;
      }
    } else {
      toastr.error('No hay productos en el carrito');
    }
  }
  render() {
    return (
      <>
        <div className='cart'>
          <h1>
            <b>Carrito de</b> compras
          </h1>
          <div className='container'>
            <table className='table'>
              <thead>
                <tr>
                  <th scope='col'>#</th>
                  <th scope='col'>Producto</th>
                  <th scope='col'>Cantidad</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.products.map((data, index) => (
                  <tr>
                    <th scope='row'>{index + 1}</th>
                    <td>{data.name}</td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i
                          onClick={() => this.minus(index)}
                          class='fas fa-minus-circle'
                        ></i>{' '}
                        <p style={{ margin: '0px 10px' }}>{data.count}</p>{' '}
                        <i
                          onClick={() => this.plus(index)}
                          class='fas fa-plus-circle'
                        ></i>
                      </div>
                    </td>
                    <td>
                      <i
                        class='fas fa-trash'
                        onClick={() => this.deleteItem(index)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className='contact_form'>
          <h1>Solicitar</h1>
          <div className='container '>
            <Form>
              <Form.Group controlId='formname'>
                <Form.Control
                  size='lg'
                  type='text'
                  value={this.state.name}
                  name='name'
                  placeholder={'Nombre y Apellido'}
                  onChange={this.handleInput}
                />
              </Form.Group>
              <Form.Group controlId='formrazonsocial'>
                <Form.Control
                  size='lg'
                  type='text'
                  name='razon'
                  value={this.state.razon}
                  placeholder={'Razón Social'}
                  onChange={this.handleInput}
                />
              </Form.Group>
              <Form.Group controlId='formcuit'>
                <Form.Control
                  value={this.state.cuit}
                  size='lg'
                  type='text'
                  name='cuit'
                  placeholder={'Cuit'}
                  onChange={this.handleInput}
                />
              </Form.Group>
              <Form.Group controlId='formphone'>
                <Form.Control
                  value={this.state.telefono}
                  size='lg'
                  type='text'
                  name='telefono'
                  placeholder={'Teléfono'}
                  onChange={this.handleInput}
                />
              </Form.Group>
              <Form.Group controlId='formemail'>
                <Form.Control
                  size='lg'
                  type='text'
                  value={this.state.email}
                  name='email'
                  placeholder={'Dirección de Correo'}
                  onChange={this.handleInput}
                  required
                />
              </Form.Group>
              <Form.Group controlId='formmensaje'>
                <Form.Control
                  size='lg'
                  as='textarea'
                  rows='4'
                  value={this.state.mensaje}
                  name='mensaje'
                  onChange={this.handleInput}
                  placeholder={'Mensaje'}
                />
              </Form.Group>
              <Button variant='secundary' onClick={() => this.sendMail()}>
                Enviar Solicitud
              </Button>
            </Form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  email_client: state.email_client
});
const mapDispatchToProps = dispatch => ({
  setBadge(n) {
    dispatch({
      type: 'SET_BADGE',
      badge: n
    });
  }
});
export default compose(connect(mapStateToProps, mapDispatchToProps))(Cart);
