import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import Glide from '@glidejs/glide';
import { Button } from 'react-bootstrap';
import api from '../../providers/api';
import './intro.css';

class Intro extends Component {
  componentDidUpdate() {
    new Glide('.glide_intro', {
      type: 'carousel',
      perview: 1
    }).mount();
  }
  handleClick() {
    console.log(this.props.productsref);
    this.props.productsref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
  render() {
    let { t } = this.props;
    return (
      <>
        <div className='intro'>
          <div className='glide glide_intro'>
            <div className='glide__track' data-glide-el='track'>
              <ul className='glide__slides'>
                {this.props.home.map(data => (
                  <li className='glide__slide' key={data.id}>
                    <div className='container'>
                      <div className='row'>
                        <div className='col-12 col-sm-6'>
                          <img
                            className='slider_image'
                            src={api.images + 'gallery/' + data.img}
                            alt='aire split'
                          />
                        </div>

                        <div
                          className='col-12 col-sm-6'
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}
                        >
                          <h2>{data.title}</h2>
                          <p style={{ color: '#6d6d6d' }}>{data.sub_title}</p>
                          <Button
                            variant='primary'
                            type='submit'
                            onClick={() => this.handleClick()}
                          >
                            {t('show_more')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className='glide__arrows' data-glide-el='controls'>
              <button
                className='glide__arrow glide__arrow--left'
                data-glide-dir='<'
              >
                <i className='fas fa-chevron-left'></i>
              </button>
              <button
                className='glide__arrow glide__arrow--right'
                data-glide-dir='>'
              >
                <i className='fas fa-chevron-right'></i>
              </button>
            </div>
            <div className='glide__bullets' data-glide-el='controls[nav]'>
              {this.props.home.map((data, index) => (
                <button
                  key={data.id}
                  className='glide__bullet'
                  data-glide-dir={'=' + index}
                ></button>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  home: state.home,
  productsref: state.productsref
});
const mapDispatchToProps = dispatch => ({
  moveToRef(ref) {
    dispatch({
      type: 'MOVE_REF',
      ref: ref
    });
  }
});
export default compose(
  translate('buttons'),
  connect(mapStateToProps, mapDispatchToProps)
)(Intro);
